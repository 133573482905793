* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  scroll-behavior: smooth;
}

.container {
  max-width: 1440px !important;
}

.separator {
  border-bottom: 0;
}

.separator::before {
  content: "";
  width: 45%;
  border-bottom: 1px solid #cfcfd7;
  margin-right: 15px;
}

.separator::after {
  content: "";
  width: 45%;
  border-bottom: 1px solid #cfcfd7;
  margin-left: 15px;
}

.button:focus-within .border-4 {
  border-color: none !important;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.textarea:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

.card {
  width: 30.5%;
}

.tab-content {
  width: 100%;
}

.api-scroll {
  min-height: 650px;
  max-height: 650px;
  height: 650px;
  overflow-y: scroll;
}

.pdf-viewer {
  height: 100%;
}

.links-list li {
  padding: 8px 20px;
  border-bottom: 1px solid #cfcfd7;
}

.custom-tabs {
  background-color: #ffff !important;
  background: none;
  padding: 0px 0px;
  margin-bottom: 0 !important;
  border-radius: 10px;
  flex-wrap: nowrap;
  margin: 5px 5px;
  border: 1px solid #023047;
}

.hebrew .nav-item button {
  text-align: right;
  padding: 10px 5px !important;
}

.nav-item {
  transition: border-color 0.3s ease-in-out;
}

.custom-tab.active {
  color: #fff;
  transition: color 0.3s ease-in-out;
  border-left: none !important;
}

.nav-link {
  color: #023047;
  text-align: center !important;
  transition: color 0.3s ease-in-out;
}

.nav-link.active {
  background: #023047 !important;
  color: #ffff !important;
  border: none;
  width: 100%;
  padding: 10px;
  text-align: center;
  border-radius: 10px !important;
  border-top-left-radius: 0 !important;
  border-left: none !important;
  border-top-right-radius: 0 !important;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.nav-link:hover.active,
.nav-link:focus.active {
  border-left: none !important;
  color: #fff;
}

.nav-link:hover {
  border: none !important;
  outline: none !important;
  border-left: none !important;
  color: transparent;
}

.tab-text.active {
  color: #219ebc !important;
  border-radius: 0px !important;
}

.main-tabs {
  border-right: 1px solid black;
}

.back-color {
  background-color: rgba(134, 134, 134, 0.56);
  width: 100%;
  height: 100%;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

/* main-console checkbox */

.expense-checkbox {
  height: 16px !important;
  width: 16px !important;
  margin: 0 .4em 0 0 !important;
  position: relative;
  border: 2px solid #000;
  border-radius: 2px;
  accent-color: #219ebc !important;
  cursor: pointer;
  line-height: 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  -webkit-appearance: none;
  opacity: .5;
}

.form-switch .form-check-input {
  width: 2.3em;
  height: 20px;
  /* border-radius: 4px; */
    transition: border-color 0.2s ease-in-out;
}
.form-switch .form-check-input:focus {
  outline: none;
    box-shadow: none;
    border-color: #dee2e6
}
.form-switch .form-check-input:checked {
  background-color: #219ebc;
  border-color: #dee2e6;
}

input[type=checkbox]:hover {
  opacity: 1;
}
/* .form-switch .form-check-input:focus {
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e);
} */
.sc-fQpRED input[type=checkbox]:checked,
.expense-checkbox:checked {
  background-color: #219ebc;
  opacity: 1;
  border: none;
}
.sc-fQpRED input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1.5px solid #8ECAE6;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}
.sc-fQpRED input[type="checkbox"]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 6px;
  height: 12px;
  border: solid #FFF;
  border-width: 0 3px 3px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}
.expense-checkbox:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 6px;
  height: 12px;
  border: solid #FFF;
  border-width: 0 3px 3px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

/* range css */
/* PercentageSlider.css */

.slider-container {
  text-align: center;
  /* margin-top: 20px; */
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
}

.slider {
  width: 80%;
  max-width: 500px !important;

  height: 5px;
  background: #d3d3d3;
  border: none;
  border-radius: 5px;
  outline: none;
  margin: 0;
  padding: 0;
  transition: background-color 0.3s ease;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #007bff;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #007bff;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.percentage-label {
  font-size: 18px;
  /* font-weight: bold; */
  /* margin-top: 10px; */
  margin-right: 5px;
  color: #333;
}

/* Hover styles for thumb */
.slider::-webkit-slider-thumb:hover,
.slider::-moz-range-thumb:hover {
  background-color: #0056b3;
  border-color: #fff;
}

/* Active (clicked) styles for thumb */
.slider::-webkit-slider-thumb:active,
.slider::-moz-range-thumb:active {
  background-color: #004186;
  border-color: #fff;
}

.nav-item {
  width: 33.4%;
  text-align: center;
  border-radius: 6px;
  transition: transform 0.3s ease-in-out;
  border-left: 1px solid #cfcfd7;
}

.nav-tabs .nav-link {
  margin-bottom: none;
  border: none;
  height: 100%;
  margin: 0;
  text-align: start;
  width: 100%;
  border-radius: 8px !important;
}

.nav-item .nav-link:focus,
.nav-item .nav-link:hover {
  color: black;
  border-radius: 8px !important;
}

.nav-item:first-child {
  border: none !important;
}

/* ant design */
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #219ebc;
  border-color: #219ebc;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
  color: #023047 !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #023047 !important;
}

.rtl-rule.ant-steps .ant-steps-item-container {
  display: flex !important;
  flex-direction: row-reverse !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
  color: #ffff !important;
  font-weight: bold !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-steps-item-tail {
  display: none !important;
}

.ant-steps-item-title {
  color: #023047 !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  color: #023047 !important;
  margin: 5px 0px 0px 0px;
  font-family: "Poppins", sans-serif !important;
}

.ant-steps-icon {
  color: #fff !important;
  font-size: 17px;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}

.ant-steps-item-description {
  font-family:  "Poppins, sans-serif";
  font-size: 13px !important;
}

.ant-steps-item-icon {
  border-radius: 6px !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* custom scroll bar  */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  scrollbar-gutter: stable;
  max-height: 20px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(74, 75, 77, 0.1);
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c1c1c1;
}

.custom-scrollbar::-webkit-scrollbar:horizontal {
  max-height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  background-color: #c1c1c1;
  width: auto;
}

/* pdf */
.shadow-pdf {
  background-image: url(../images/doc-file.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  background-position: center;
  border-radius: 8px;
}

/* content-box */

.content-box {
  border: 1px solid #c1c1c1;
  border-radius: 8px;
  margin: 10px 10px;
  padding: 20px 10px;
}

/* loader css */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #219ebc;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.form-select-select {
  -webkit-appearance: none !important;
  appearance: none;
  background-image: url(../images/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 12px;
  /* z-index: 9; */
}

.form-select-select-status {
  -webkit-appearance: none !important;
  appearance: none;
  background-image: url(../images/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 12px;
  z-index: 9;
  padding-left: 38px;
}

.search-icon::before {
  content: url(../images/search-icon.svg);
  position: absolute;
  top: 12.5px;
  left: 12px;
}

.search-icon-hebrew::before {
  content: url(../images/search-icon.svg);
  position: absolute;
  top: 12.5px;
  right: 6px;
}

.dataTable-text, .dataTable-text-right {
  color: #023047;
  font-size: 13px;
  /* font-family:  "Poppins, sans-serif" !important; */
  font-style: normal;
  font-weight: 600;
  line-height: 18.52px;
  text-transform: uppercase;
}
.dataTable-text-right{
  font-size: 14px !important;
}
.dataTable-text:hover {
  color: #023047 !important;
}

.juhAha:hover,
.juhAha:focus {
  opacity: 1 !important;
  color: #023047 !important;
}

.column-text {
  color: #000000 !important;
  /* font-family:  "Poppins, sans-serif"; */
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.96px;
}

.datatable-width-set .gRfwAR:hover {
  color: none !important;
  background: none !important;
  outline: none !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-bottom-color: rgba(0, 0, 0, .12) !important;
}

.datatable-width-set .gRfwAR {
  min-height: 54px !important;
}

.gRfwAR {
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-bottom-color: rgba(0, 0, 0, .12) !important;
}



/* input[type="checkbox"]:checked {
  background-color: #8ECAE6;
  border-color: #8ECAE6;
}

input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 2.5px;
  left: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
} */

/* Conditional classes  for status update */
.missing-text {
  color: #F8285A !important;
  text-align: center;
  background-color: #FFEEF3;
  border-radius: 10px !important;
  font-size: 12px;
  padding: 6px 12px;
  width: inherit;
}

.connected-text {
  color: #17C653 !important;
  text-align: center;
  background-color: #DFFFEA;
  border-radius: 10px !important;
  font-size: 12px;
  padding: 6px 12px;
  width: inherit;
}

.not-valid-text {
  color: #F8285A !important;
  text-align: center;
  background-color: #FFEEF3;
  border-radius: 10px !important;
  font-size: 12px;
  padding: 6px 12px;
  width: inherit;
}

.not-connected-text {
  color: #FF6F1E !important;
  text-align: center;
  background-color: #FFF5EF;
  border-radius: 10px !important;
  font-size: 12px;
  padding: 6px 12px;
  width: inherit;
}
.modal-width-handle{
  width: 48%;
}
.modal-pedding{
  padding-left: 144px !important;
}
.hNKPiN {
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* border: 1px solid red; */
}
.dKhQoE{
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* border: 1px solid red; */
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* media queries */
@media (max-width: 1440px) {
  .custom-paragraph {
    font-size: 11px !important;
  }
}

@media (max-width: 1400px) {
  .custom-tabs {
    flex-direction: column !important;
  }

  .custom-table-listing {
    flex-direction: row !important;
  }

  .custom-table-listing-card {
    flex-direction: row-reverse !important;
  }

  .custom-paragraph {
    font-size: 10px !important;
  }

  .nav-item {
    width: 100%;
  }
}

@media (max-width: 1280px) {
  .links-list li {
    padding: 8px 10px;
  }
}

@media (max-width: 992px) {
  .card {
    width: 100%;
    padding: 15px 6px !important;
  }

  .console-responsive {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .custom-tabs {
    flex-direction: row !important;
  }
  .modal-width-handle{
    width: 96% !important;
  }
  .modal-pedding{
    padding-left: 0px !important;
  }
}

@media (max-width: 767px) {
  .ant-steps.ant-steps-vertical {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .api-scroll {
    min-height: auto;
    max-height: auto;
    height: auto;
    overflow-y: scroll;
  }

  .doc-inline {
    gap: 15px !important;
  }

  .nav-item {
    max-width: 33.3%;
  }

  .ant-steps-item-title {
    font-size: 18px !important;
  }

  .Amount-input {
    margin-top: 14px !important;
  }

  .Amount-input-data {
    margin: 0 !important;
  }

  .nav-item {
    font-size: 14px !important;
  }
  .modal-width-handle{
    width: 96%;
  }
  .modal-pedding{
    padding-left: 0px !important;
  }
}

.pdf-display {
  display: block;
}

@media (max-width: 750px) {
  .creditCard {
    width: 100% !important;
  }
}
.tooltip-inner {
  text-align: inherit !important;
}

.tooltip-right .tooltip-inner {
  text-align: right !important;
}

.tooltip-left .tooltip-inner {
  text-align: left !important;
}